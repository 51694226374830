import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/careers.module.scss'
import ItemPosition from 'components/itemPosition'

class LavoraConNoi extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Lavora con noi" description={'Entra a far parte del team Dinamiza, l\'agenzia digitale a Venezia con focus sulla creazione di piattaforme web integrate, applicazioni ecommerce, siti web.'}/>

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}>Siamo sempre alla ricerca<br />di <Dinamiza>nuovi talenti</Dinamiza></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>

                  <ItemPosition url={'/lavora-con-noi/wordpress-developer'}>
                    <h2><Light>Wordpress Developer</Light></h2>
                  </ItemPosition>
                  <br/><br/>
                  <ItemPosition url={'/lavora-con-noi/backend-developer'}>
                    <h2><Light>Backend developer Symfony e/o Laravel</Light></h2>
                  </ItemPosition>
                  <br/><br/>
                  <ItemPosition url={'/lavora-con-noi/frontend-developer'}>
                    <h2><Light>Front end Developer</Light></h2>
                  </ItemPosition>
                  <br/><br/>
                  <ItemPosition url={'/lavora-con-noi/web-developer-stage'}>
                    <h2><Light>Web Developer - Stage</Light></h2>
                  </ItemPosition>

                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default LavoraConNoi
